import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'router'
import { useForm } from 'formular'
import localStorage from 'local-storage'
import { track } from 'analytics'
import links from 'links'
import { useFt } from 'hooks'
import { openModal } from 'modals'
import { constants, processError } from 'helpers'
import { email, required } from 'helpers/validators'
import { useMagicLinkCreate, useLogIn } from 'modules/user'
import { useGiftSubscription } from 'modules/gifts'
import { Text } from 'components/dataDisplay'

import { useAuthPage } from 'containers/AuthPageProvider/AuthPageProvider'

import messages from '../messages'


const useLoginPage = () => {
  const [ isError, setError ] = useState(false)
  const { giftSubscription } = useGiftSubscription()
  const history = useHistory()

  const isMagicLinkEnabled = useFt(constants.features.magicLinkWeb)

  const form = useForm<LoginPage.Form>({
    fields: {
      email: [ required, email ],
      password: isMagicLinkEnabled ? [] : [ required ],
    },
    initialValues: {
      email: '',
    },
  })

  useEffect(() => {
    const email = giftSubscription?.email || localStorage.getSessionItem(constants.localStorageNames.authEmail) || ''
    form.fields.email.set(email)
  }, [ giftSubscription, form ])

  const { flow, isFlow, isExpired, onRedirect, redirectUrl } = useAuthPage()
  const redirectUrlRef = useRef(redirectUrl)

  redirectUrlRef.current = redirectUrl

  useEffect(() => {
    track('Login page', {
      flow,
    })
  }, [ flow ])

  const [ submitLogin, { isFetching: isLoginSubmitting } ] = useLogIn()
  const [ submitMagicLink, { isFetching: isMagicLinkSubmitting } ] = useMagicLinkCreate()

  const isSubmitting = isLoginSubmitting || isMagicLinkSubmitting

  const handleLoginClick = useCallback(async () => {
    try {
      setError(false)

      const { values: { email, password }, errors } = await form.submit()

      localStorage.setSessionItem(constants.localStorageNames.authEmail, email)

      if (errors) {
        const fieldErrors = Object.keys(errors).map((field) => `${field}: ${errors[field].en}`).join('. ')

        track('Login fail', {
          reason: `${fieldErrors}`,
        })

        return
      }

      if (isMagicLinkEnabled) {
        track('Send login link click')

        await submitMagicLink({
          email,
          redirectUrl: redirectUrlRef.current,
        })

        openModal('commonModal', {
          title: messages.magicLinkModal.title,
          text: messages.magicLinkModal.text,
          children: (
            <div className="flex h-full flex-col justify-end">
              <Text message={messages.magicLinkModal.additionalContent} style="p2" />
            </div>
          ),
          primaryButton: {
            toTab: 'https://scentbird.zendesk.com/hc/en-us/articles/8212812478619-I-haven-t-received-the-Magic-Link-email-What-can-I-do-',
            title: messages.magicLinkModal.buttonTitle,
            fullWidthOnMobile: true,
          },
          'data-testid': 'magicLinkSentModal',
        })
      }
      else {
        await submitLogin({ email, password })
        await onRedirect()
      }
    }
    catch (error) {
      if (error.cause?.__typename === 'UserLogInError') {
        const { errorCode, message } = error.cause

        if (errorCode === 'USER_SHOULD_RESET_PASSWORD') {
          history.push(links.recoverPassword)
          return
        }

        track('Login fail', {
          reason: message || errorCode,
        })

        setError(true)
        return
      }

      track('Login fail', {
        reason: error.message,
      })

      processError(error)
    }
  }, [ form, onRedirect, history, isMagicLinkEnabled, submitLogin, submitMagicLink ])

  return {
    form,
    isFlow,
    isError,
    isSubmitting,
    handleLoginClick,
    isMagicLinkEnabled,
    isExpired,
  }
}


export default useLoginPage
