import React from 'react'
import links from 'links'
import { useSearch, useSearchParams } from 'router'
import { track } from 'analytics'
import { useSSR } from 'hooks'

import { Text } from 'components/dataDisplay'
import { Href } from 'components/navigation'
import { Button, Form, Input } from 'components/inputs'
import EmailInput from 'compositions/inputs/EmailInput/EmailInput'

import AuthPageContainer from '../components/AuthPageContainer/AuthPageContainer'
import SocialButtons from '../components/SocialButtons/SocialButtons'
import ErrorMessage from '../components/ErrorMessage/ErrorMessage'

import useLoginPage from './util/useLoginPage'

import messages from './messages'


const LoginPage: React.FunctionComponent = () => {
  const { form, isFlow, isError, isSubmitting, handleLoginClick, isMagicLinkEnabled, isExpired } = useLoginPage()
  const [ { exists } ] = useSearchParams()
  const search = useSearch()
  const isSSR = useSSR()

  const noAccountLinkNode = (
    <Href
      className="link-gold-50 text-p4 underline"
      to={`${links.register}${search}`}
      message={messages.noAccount}
      onClick={() => {
        track('Create account link click')
      }}
      data-testid="noAccountLink"
    />
  )

  const submitButtonNode = (
    <Button
      className={isMagicLinkEnabled ? 'mt-32' : 'mt-24'}
      size={56}
      fullWidth
      title={isMagicLinkEnabled ? messages.magicLinkButtonTitle : messages.buttonTitle}
      disabled={isSSR}
      loading={isSubmitting}
      type="submit"
      data-testid="loginButton"
    />
  )

  const title = isMagicLinkEnabled && isExpired ? messages.expiredTitle : (
    isFlow ? messages.flowTitle : messages.title
  )

  return (
    <AuthPageContainer>
      <Text
        message={title}
        style="sh3"
        tag="h1"
        html
      />
      {
        Boolean(exists) && (
          <Text
            className="mt-24"
            style="p3"
            message={messages.emailExists}
          />
        )
      }
      <Form
        className="mt-24"
        loading={isSubmitting}
        onSubmit={handleLoginClick}
      >
        <EmailInput
          className="mt-12"
          type="email"
          size={48}
          label={messages.email}
          field={form.fields.email}
          autoComplete="username"
          data-testid="email"
        />
        {
          isMagicLinkEnabled ? (
            <>
              <Text className="mt-16" style="p2" message={messages.magicLinkHint} />
              {submitButtonNode}
              <div className="mt-24 flex justify-center">
                {noAccountLinkNode}
              </div>
            </>
          ) : (
            <>
              <Input
                type="password"
                className="mt-16"
                size={48}
                label={messages.password}
                field={form.fields.password}
                autoComplete="current-password"
                data-testid="password"
              />
              <div className="mt-16 flex justify-between">
                <Href
                  className="link-black text-p4 underline"
                  to={links.forgotPassword}
                  message={messages.forgotPassword}
                  data-testid="forgotPasswordLink"
                />
                {noAccountLinkNode}
              </div>
              {
                isError && (
                  <ErrorMessage
                    className="mb-8 mt-32"
                    text={{
                      ...messages.error,
                      values: {
                        resetLink: links.forgotPassword,
                      },
                      components: { Href },
                    }}
                    data-testid="loginError"
                  />
                )
              }
              {submitButtonNode}
            </>
          )
        }
      </Form>
      <SocialButtons className="mt-40" />
    </AuthPageContainer>
  )
}


export default LoginPage
